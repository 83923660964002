.site-background {
  /* background-color: rgba(245, 231, 252, 1); */
  background-color: #7b5eea;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 60%;
  z-index: -1;
  overflow: hidden;
}

.ocean {
  height: 5%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: white;
  z-index: 1;
}

.wave {
  background: url('../images/wave.svg') repeat-x;
  position: absolute;
  top: -100px;
  width: 6400px;
  height: 100px;
  animation: wave 35s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
  top: -56px;
  animation: wave 20s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 7s ease -1.25s infinite;
  opacity: 1;
}

@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -45px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}
